@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");

body {
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 8px;
}

h1 {
  text-align: center;
  color: #9e16bd;
}

h2 {
  color: #9e16bd;
}

section {
  margin-bottom: 20px;
}

button {
  background-color: #9e16bd;
  color: #ffffff;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 5px;
}

button:hover {
  background-color: #3a0546;
  transform: scale(1.05);
}

.response {
  background-color: #2e2e2e;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  white-space: pre-wrap; /* Mantém a formatação de quebras de linha */
  overflow-wrap: break-word; /* Quebra palavras longas automaticamente */
}

.highlight {
  padding: 20px;
}

pre {
  word-wrap: break-word; /* Quebra palavras longas */
  white-space: pre-wrap; /* Quebra de linhas automáticas */
  width: 100%; /* Ajusta a largura ao tamanho do container */
}

.title-and-image {
  display: flex;
  justify-content: space-around;
}

.title-and-image img {
  position: relative;
  left: 50px;
  display: flex;
  justify-content: end;
  width: 50%;
}

.logo_mariah_ia {
  left: 15% !important;
  width: 50% !important;
}

#response-buscar-descricoes-imagens {
  /* background-color: red; */
  margin-left: -20px;
  margin-right: -20px;
}

#button-group-buscar-descricoes-imagens {
  margin-left: -20px;
  margin-right: -20px;
}

.section-text-area-gerenciar-arquivo {
  display: flex;
  justify-content: center;
  align-items: self-start;
  flex-direction: column;
}

textarea {
  position: relative;
  width: 97.3%;
  min-height: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 10px;
  padding: 10px;
  background-color: #2e2e2e;
  color: #ffffff;
  border: 1px solid #9e16bd;
  left: -10px;
  resize: vertical;
}

#resposta-comando-prompt {
  width: 97.5%;
}

label {
  color: #ffffff;
  text-shadow: rgb(0, 0, 0) 1px 1px 3px;
}

.campo-input-gerar-primeira-e-ultima-pagina {
  margin: 5px;
}

.campo-input-gerar-primeira-e-ultima-pagina > textarea {
  max-width: 94%;
  min-height: 50px !important;
  max-height: 100px !important;
}

.campo-input-gerar-primeira-e-ultima-pagina > input {
  height: 13px;
}

.campo-input-gerar-primeira-e-ultima-pagina > select {
  width: 100%;
}

.campo-de-ambientes-gerar-laudo-primeira-e-ultima-pagina {
  margin-top: 3px;
}

input:focus {
  outline: none;
  border: 3px solid #9e16bd;
}

.lados-form {
  display: flex;
  flex-direction: column;
}

.lados-form > div {
  margin: 5px;
}

.Inputs-e-relatorio-geral-de-apontamentos {
  display: flex;
}

.principal-gerador-de-laudos {
  display: flex;
  flex-wrap: wrap;
}

.form-lado-esquerdo-e-direito-juntos {
  display: flex;
  justify-content: space-between; /* Distribui o espaço igualmente */
}

.relatorio-geral-de-apontamentos-check-box > table > tbody > tr > td {
  font-size: 0.8rem;
  text-align: start;
  padding: 0;
}

input[type="checkbox"] {
  width: 11px;
  height: 11px;
  vertical-align: middle; /* Alinha verticalmente com o texto */
  margin-left: 5px;
  appearance: none; /* Remove o estilo padrão */
  background-color: #ffffff; /* Cor de fundo inicial */
}

input[type="checkbox"]:checked {
  background-color: #9e16bd; /* Cor ao ser clicada */
}

table > div {
  display: flex;
  justify-content: space-between;
}

.titulo-relatorio-geral-de-apontamentos-e-Consta-Apontamento {
  display: flex;
  justify-content: space-between;
  width: 135%;
  /* background-color: yellow; */
}

.titulo-relatorio-geral-de-apontamentos-e-Consta-Apontamento > div {
  display: flex;
  /* background-color: red; */
  font-size: 0.6rem;
  height: 100%;
  margin-left: 1px;
}

.relatorio-geral > th {
  text-align: start;
}

.texto-relatorio-geral {
  font-size: 0.6rem;
  font-weight: 700;
}

.texto-de-apontamentos {
  font-size: 0.5rem;
  font-weight: 700;
}

.titulo-sim-nao-inexiste {
  display: flex;
  width: 129%;
  justify-content: end;
  font-size: 0.6rem;
}

.relatorio-geral-de-apontamentos-check-box > table {
  width: 100%;
  margin-left: 1.7rem;
}

.relatorio-geral-de-apontamentos-check-box {
  margin-top: 8px;
}

.texto-gerador-de-vistorias {
  margin-left: 0.8rem;
}
